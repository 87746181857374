import { useMediaQuery, useTheme } from '@mui/material';
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

type MenuItem = {
  label: string;
  anchorId: string;
  isAnchor: boolean;
};

type LandingContextProps = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  theme: any;
  isMobile: boolean;
  menuItems: MenuItem[];
  scrolled: boolean;
  aboutUsTabButton: any;
  aboutUsTabValue: any;
  setAboutUsTabValue: any;
  product: any;
  setProduct: any;
  activeItem: string;
  formActive: boolean;
  setActiveItem: (params: any) => void;
  kirimPesanWhatsApp: (pesan: string, deskripsi: string) => void;
  kirimPesanEmojiWhatsApp: (pesan: string, deskripsi: string) => void;
  openContactForm: () => void;
  closeContactForm: () => void;
};

const LandingContext = createContext<LandingContextProps | undefined>(
  undefined
);

export const useLanding = (): LandingContextProps => {
  const context = useContext(LandingContext);
  if (!context) {
    throw new Error('useLanding must be used within a LandingProvider');
  }
  return context;
};

type LandingProviderProps = {
  children: ReactNode;
};




export const LandingProvider: FC<LandingProviderProps> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState('home'); // State to track active item
  const [formActive, setFormActive] = useState(false);

  const aboutUsTabButton = ['Our Value', 'Our Vision', 'Our Mission'];
  const [aboutUsTabValue, setAboutUsTabValue] = useState<string>(
    aboutUsTabButton[0]
  );

  const toggleSidebar = () => setSidebarToggle((prev) => !prev);
  const closeSidebar = () => setSidebarToggle(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems: MenuItem[] = [
    { label: 'HOME', isAnchor: true, anchorId: 'home' },
    { label: 'PRO CLIENTS', isAnchor: false, anchorId: 'clients' },
    { label: 'PRO TEAMS', isAnchor: false, anchorId: 'team' },
    { label: 'PRO SERVICES', isAnchor: false, anchorId: 'service' },
    { label: 'ARTICLES', isAnchor: false, anchorId: 'article' },
    { label: 'CONTACT US', isAnchor: false, anchorId: 'contact-us' }

    // { label: 'FAQ', isAnchor: true, anchorId: 'faq' }
  ];

  function kirimPesanWhatsApp(pesan: string, deskripsi: string) {
    // Base URL WhatsApp dengan nomor telepon (gunakan nomor sesuai kebutuhan)
    const nomorTelepon = '628118111241'; // Ganti dengan nomor WhatsApp tujuan
    const baseURL = `https://wa.me/${nomorTelepon}`;

    // Mengganti spasi dengan %20 untuk URL encode dan menyusun pesan
    const textMessage = encodeURIComponent(
      `${pesan}${deskripsi ? `\n\nDeskripsi: ${deskripsi}` : ''}`
    );
    const url = `${baseURL}?text=${textMessage}`;

    // Membuka link WhatsApp dengan pesan yang sudah di-encode
    window.open(url, '_blank');
  }

  function kirimPesanEmojiWhatsApp(pesan: string, deskripsi: string) {
    // Base URL WhatsApp dengan nomor telepon (gunakan nomor sesuai kebutuhan)
    const nomorTelepon = '628118111241'; // Ganti dengan nomor WhatsApp tujuan

    const DEFAULT_MESSAGE = `Hi!👋🏼 Boleh tolong bantu konsultasi dan saran marketing apa yang tepat untuk bisnis saya?`;

    const baseURL = `https://api.whatsapp.com/send/?phone=${nomorTelepon}`;
    // Mengganti spasi dengan %20 untuk URL encode dan menyusun pesan
    const textMessage = encodeURIComponent(
      pesan
        ? `${pesan}${deskripsi ? `\n\nDeskripsi: ${deskripsi}` : ''}`
        : DEFAULT_MESSAGE
    );
    const url = `${baseURL}&text=${textMessage}&type=phone_number&app_absent=0`;

    // Membuka link WhatsApp dengan pesan yang sudah di-encode
    window.open(url, '_blank');
  }

  const openContactForm = () => setFormActive(true);
  const closeContactForm = () => setFormActive(false);

  const [product, setProduct] = useState(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // console.log(product);
  }, [product]);

  return (
    <LandingContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        theme,
        isMobile,
        formActive,
        menuItems,
        scrolled,
        aboutUsTabButton,
        aboutUsTabValue,
        setAboutUsTabValue,
        product,
        setProduct,
        activeItem,
        setActiveItem,
        kirimPesanWhatsApp,
        kirimPesanEmojiWhatsApp,
        openContactForm,
        closeContactForm
      }}
    >
      {children}
    </LandingContext.Provider>
  );
};
