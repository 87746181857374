import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { EmployeeProvider } from './contexts/EmployeeContext';
import BarLayout from './layouts/BarLayout';
import { employeeRoute } from './route/master/EmployeeRoute';
import { dashboardRoute } from './route/dashboard/DashboardRoute';
import { landingRoute } from './route/landing/LandingRoute';
export const Loader = (Component: any) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Dashboards

// Applications
const LoginLayout = Loader(lazy(() => import('src/layouts/LoginLayout/LoginLayout')));





// Components

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const routes: RouteObject[] = [
  // APP ---

  {
    path: 'admin',
    element: <BarLayout />,
    children: [
      ...employeeRoute(Loader),
      ...dashboardRoute(Loader)
    ]
  },
  {
    path: 'login',
    element: <LoginLayout />
  },

  // # Docs ---
  {
    path: '',
    // element: <BaseLayout />,
    children: [
      ...landingRoute(Loader),
      // { path: '/', element: <Overview /> },
      // { path: 'overview', element: <Navigate to="/" replace /> },
      { path: '*', element: <Status404 /> }
    ]
  },
 
];

export default routes;
