import { lazy } from 'react';
import { LandingProvider } from 'src/contexts/LandingContext';
import AffiliatorManagementService from 'src/pages/landing/components/services/AffiliatorManagementService';
import AppsDevelopmentService from 'src/pages/landing/components/services/AppsDevelopmentService';
import BrandActivationService from 'src/pages/landing/components/services/BrandActivationService';
import DigitalAdsService from 'src/pages/landing/components/services/DigitalAdsService';
import ECommerceEnablerService from 'src/pages/landing/components/services/ECommerceEnablerService';
import KOLManagementService from 'src/pages/landing/components/services/KOLManagementService';
import LiveStreamingService from 'src/pages/landing/components/services/LiveStreamingService';
import TVCPhotoProductionService from 'src/pages/landing/components/services/TVCPhotoProductionService';
import WebDevelopmentService from 'src/pages/landing/components/services/WebDevelopmentService';
import DetailArticle from 'src/pages/landing/DetailArticle';
import OurTeams from 'src/pages/landing/OurTeam';
import Services from 'src/pages/landing/Services';

export const landingRoute = (loader: any) => {
  const LandingPage = loader(lazy(() => import('src/layouts/LandingLayout')));

  const LandingIndex = loader(lazy(() => import('src/pages/landing/Index')));
  const Client = loader(lazy(() => import('src/pages/landing/Clients')));
  const ClientDetails = loader(
    lazy(() => import('src/pages/landing/ClientDetails'))
  );
  const Article = loader(lazy(() => import('src/pages/landing/Article')));
  const ContactUs = loader(lazy(() => import('src/pages/landing/ContactUs')));
  const ArticleAll = loader(lazy(() => import('src/pages/landing/ArticleAll')));

  // Services Detail
  const ContentCreationService = loader(
    lazy(
      () =>
        import('src/pages/landing/components/services/ContentCreationService')
    )
  );

  return [
    {
      path: '/',
      children: [
        { path: '', element: <LandingIndex /> },

        { path: 'clients', element: <Client /> },
        { path: 'clients/detail', element: <ClientDetails /> },

        { path: 'article', element: <Article /> },
        { path: 'article/all', element: <ArticleAll /> },
        { path: 'article/:id', element: <DetailArticle /> },
        
        { path: 'service', element: <Services /> },
        {
          path: 'service/content-creation',
          element: <ContentCreationService />
        },
        { path: 'service/kol-management', element: <KOLManagementService /> },
        {
          path: 'service/tvc-photo-production',
          element: <TVCPhotoProductionService />
        },
        {
          path: 'service/ecommerce-enabler',
          element: <ECommerceEnablerService />
        },
        { path: 'service/livestreaming', element: <LiveStreamingService /> },
        {
          path: 'service/apps-development',
          element: <AppsDevelopmentService />
        },
        { path: 'service/web-development', element: <WebDevelopmentService /> },
        {
          path: 'service/brand-activation',
          element: <BrandActivationService />
        },
        { path: 'service/digital-ads', element: <DigitalAdsService /> },
        {
          path: 'service/affiliator-management',
          element: <AffiliatorManagementService />
        },
        
        { path: 'team', element: <OurTeams /> },
        { path: 'contact-us', element: <ContactUs /> },
      ],
      element: (
        <>
          <LandingProvider>
            <LandingPage />
          </LandingProvider>
        </>
      )
    }
  ];
};
